<!--
 * @Author: Leif
 * @Date: 2021-11-05 13:44:27
 * @LastEditTime: 2022-08-19 12:56:20
 * @LastEditors: ch3nh2
 * @Description: 视频监控调度 - 视频监控画面
-->
<template>
  <div class="monitoring el-collapse-container">
    <template v-if="flag">
      <div style="
          width: 100%;
          height: 93%;
          bottom: 0px;
          position: absolute;
          background-color: #333;
          filter: Alpha(Opacity=60);
          opacity: 0.6;
          z-index: 9999;
        "></div>
    </template>
    <Navigation :isNavigation="!$route.query.hideNavigation" />
    <el-container>
      <el-aside class="leftaside" :class="{ 'left-collapse': isLeftCollapse }">
        <i class="el-arrow" @click="onLeftCollapse"></i>
        <div class="sidebar">
          <el-tabs v-model="leftActiveName" class="general_tabs">
            <el-tab-pane label="分层监控" name="monitoringAidsList">
              <div class="addressBook_area">
                <MonitoringList ref="monitoringListRefs" @checkedVideo="checkedVideo" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-aside>
      <el-main>
        <div :class="{
          monitoring_wrap: true,
          fullScreen: isFullScreen
        }">
          <div class="monitoring_tabs" v-show="!isFullScreen">
            <div :class="{
              monitoring_item: true,
              active: index == tabActiveInd,
            }" v-for="(item, index) in tabArr" :key="index" @click="selectMonitoring(item, index)">
              <i :class="item.type"></i>
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="monitoring_main">
            <div class="icon_fullScreen" @click="onFullScreen"></div>
            <MonitoringScreen v-if="!flag" :type="tabActiveType" :typePlus="tabActiveTypePlus" :sourceNum="tabActiveNum"
              :sourceList="sourceList" @removeCheck="removeCheck" class="monitoringScreen" />
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import BigPlayer from "components/InsidePages/BigPlayer";
import MonitoringList from "components/InsidePages/MonitoringList";
import MonitoringScreen from "components/InsidePages/MonitoringScreen";
import Navigation from "components/InsidePages/Navigation";
import { ElMessage } from "element-plus";
export default {
  name: "AddressBook",
  data() {
    return {
      EventLogTran: false,
      leftActiveName: "monitoringAidsList",
      rightActiveName: "InMeeting",
      tabArr: [
        { name: "监控画面4*4排列展示", type: 'fourColumn', num: 16 },
        { name: "监控画面3*3排列展示", type: "threeColumn", num: 9 },
        { name: "监控画面2x2排列展示", type: "twoColumn", num: 4 },
        { name: "监控画面1+N排列展示", type: "onePlusColumn", num: 8 },
        { name: "监控画面单屏展示", type: "oneColumn", num: 1 }
      ],
      leftTabs: [
        {
          name: "3D",
          plane: "monitoringAidsGraph",
        },
        {
          name: "列表",
          plane: "monitoringAidsList",
        },
      ],
      leftTabsActiveIndex: 0,
      tabActiveStatus: 'normal',  //normal: 正常; zoom: 缩放; pack: 收起;
      tabActiveInd: this.$route.query.hideNavigation ? 2 : 0,
      tabActiveNum: this.$route.query.hideNavigation ? 4 : 16,
      tabActiveType: this.$route.query.hideNavigation ? 'twoColumn' : "fourColumn",
      tabActiveTypePlus: '',
      // 收起展开
      isLeftCollapse: false,
      isFullScreen: false,
      flag: true,
      sourceList: [],
      // 双击放大
      bigPlayerVisible: false,
      bigPlayerData: {},
    };
  },
  watch: {
    tabActiveStatus() {
      this.onChangeTab()
    },
    tabActiveType() {
      this.onChangeTab()
    }
  },
  computed: {},
  components: {
    BigPlayer,
    Navigation,
    MonitoringList,
    MonitoringScreen,
  },
  props: {},
  methods: {
    onLeftCollapse() {
      this.isLeftCollapse = !this.isLeftCollapse;
      this.tabActiveStatus = this.isLeftCollapse ? 'pack' : 'normal'
    },
    onFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.tabActiveStatus = this.isFullScreen ? 'zoom' : this.isLeftCollapse ? 'pack' : 'normal'
    },
    leftTabChange(item) {
      this.leftActiveName = item.plane;
    },
    selectMonitoring(item, index) {
      const { type, num } = item;
      this.tabActiveInd = index;
      this.tabActiveType = type;
      this.tabActiveNum = num;
    },
    onChangeTab() {
      const key = this.tabActiveStatus
      if (key != 'normal') {
        this.tabActiveTypePlus = `${this.tabActiveType}_${this.tabActiveStatus}`
      } else {
        this.tabActiveTypePlus = ''
      }
    },
    showBigPlayer(data) {
      this.bigPlayerData = data;
      this.bigPlayerVisible = true;
    },
    closeBigPlayer() {
      this.bigPlayerVisible = false;
    },
    checkedVideo(arr) {
      this.sourceList = arr;
    },
    removeCheck(arr) {
      const filtersList = arr.filter((item) => { return item.id });
      this.$refs.monitoringListRefs.removeCheck(filtersList);
    }
  },
  created() {
    var showflag = localStorage.getItem("ACCESS_PERMISSIONS");
    if (showflag.split(",").length >= 1) {
      if (showflag.split(",").findIndex((item) => item == "b003") >= 1) {
        this.flag = false;
      } else {
        ElMessage.error("暂无权限！");
      }
    } else {
      ElMessage.error("暂无权限！");
    }
  },
  mounted() {
    // const { configList } = this.$store.state.monitordata.machineList;
    // this.sourceList = configList
  },
};
</script>

<style lang="scss" scoped>
.monitoring {
  padding: 10px 0;
  position: relative;

  .sidebar {
    background: #202331;
    height: 890px;
    overflow-y: scroll;
  }

  //公用小标题
  .general_header_area {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8);

    .general_header_left {
      position: relative;
      text-indent: 20px;
      line-height: 30px;
      white-space: nowrap;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        left: 0;
        top: 9px;
        background: #4d79f5;
      }
    }

    .general_header_right {
      display: flex;

      .general_header_item {
        width: 60px;
        height: 30px;
        background: rgba(70, 77, 98, 0.7);
        border-radius: 4px;
        margin-left: 10px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        cursor: pointer;
      }

      .active {
        background: #6a7693;
      }
    }
  }

  .monitoring_wrap {
    background: #202331;
    position: relative;

    .icon_fullScreen {
      z-index: 99;
      width: 48px;
      height: 48px;
      background: url(../../assets/icons/icon_fullScreen.png) no-repeat;
      background-size: 100%;
      position: absolute;
      right: 18px;
      top: 14px;
      opacity: 0.4;
      transition: opacity 0.2s ease-out;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .monitoring_tabs {
      display: flex;
      background: #2a2e3a;

      .monitoring_item {
        cursor: pointer;
        font-size: 16px;
        padding: 15px 30px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;

        i {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        span {
          margin-top: 12px;
        }

        .fourColumn {
          background: url(../../assets/icons/4_icon.png) no-repeat;
          background-size: 100%;
        }

        .threeColumn {
          background: url(../../assets/icons/3_icon.png) no-repeat;
          background-size: 100%;
        }

        .twoColumn {
          background: url(../../assets/icons/2x2_icon@2x.png) no-repeat;
          background-size: 100%;
        }

        .onePlusColumn {
          background: url(../../assets/icons/1+N_icon@2x.png) no-repeat;
          background-size: 100%;
        }

        .oneColumn {
          background: url(../../assets/icons/1_icon@2x.png) no-repeat;
          background-size: 100%;
        }
      }

      .active {
        background: #373c4c;

        .fourColumn {
          background: url(../../assets/icons/4x4_icon_active@2x.png) no-repeat;
          background-size: 100%;
        }

        .threeColumn {
          background: url(../../assets/icons/3x3_icon_active@2x.png) no-repeat;
          background-size: 100%;
        }

        .twoColumn {
          background: url(../../assets/icons/2x2_icon_active@2x.png) no-repeat;
          background-size: 100%;
        }

        .onePlusColumn {
          background: url(../../assets/icons/1+N_icon_active@2x.png) no-repeat;
          background-size: 100%;
        }

        .oneColumn {
          background: url(../../assets/icons/1_icon_active@2x.png) no-repeat;
          background-size: 100%;
        }
      }
    }

    .monitoring_main {
      padding: 20px;
    }
  }

  .fullScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 999;

    .icon_fullScreen {
      cursor: pointer;
      background: url(../../assets/icons/icon_normalScreen.png) no-repeat;
      background-size: 100%;
    }

    .monitoring_main {
      .monitoringScreen {
        height: 960px;
      }
    }
  }

  .floor_wrap {
    .floor_item {
      position: relative;
      margin-bottom: 10px;
    }
  }

  .icon_camera {
    position: absolute;
    width: 28px;
    height: 28px;
    border: 1px solid #2863ed;
    border-radius: 50%;
    background: url(../../assets/image/pages/MonitoringSchedule/monitor@2x.png) no-repeat #00132d center center;
    background-size: 17px 17px;

    &::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 4px;
      background: #2863ed;
      border-radius: 50%;
      top: 35px;
      left: 7px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 8px;
      background: #2863ed;
      top: 28px;
      left: 13px;
    }
  }
}

.el-tabs__header {
  display: none;
}

.el-collapse-container {
  .el-tabs__nav-wrap {
    padding: 0;

    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      display: none;
    }
  }

  .el-aside {
    width: 24%;
    transition: all 0.3s;
    -webit-transition: all 0.3s;
    position: relative;
    overflow: visible;
  }

  .el-aside::-webkit-scrollbar {
    width: 0;
  }

  .el-main {
    padding: 0;
  }

  .leftaside {
    margin-right: 1.5%;

    .el-arrow {
      right: -26px;
    }
  }

  .left-collapse {
    width: 0%;
    transform-origin: 0% 0%;

    .el-arrow {
      transform: rotate(180deg);
    }
  }

  .el-arrow {
    display: block;
    width: 26px;
    height: 26px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/image/pages/commom/arrow.png");
    position: absolute;
    top: 50%;
    margin-top: -13px;
    z-index: 2;
    cursor: pointer;
  }
}

.el-tabs__header {
  margin-bottom: 0;
}
</style>
